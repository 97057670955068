import React, { useEffect, useState } from 'react';
import { Select, FormContainer, FormItem, Divider } from 'elmo-elements';
import { Data, DropDownProps, FormProps } from '../type';
import { getSelectedGroupedValues, getSelectedValues } from '../helpers';
import { getPreferenceLabel } from 'lib/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getLangPreferences } from '../../../../../state/Account';
import { getProjects } from 'state/Projects/selectors';
import { FeatureFlag } from 'element/feature-flags-components';
import { GET_PROJECTS_REQUEST } from 'state/Projects/actions';


export type DataProps = {
  handleOnChangeSelect2: (e: DropDownProps[], name: string) => void;
  handleOnChangeProjects: (selectedOptions: any[]) => void;
  data: Data;
  type: FormProps['type'];
  rolesAsSelectOptionsArray: any;
  sitesAsSelectOptionsArray: any;
  usersAsSelectOptionsArray: any;
  areasAsSelectOptionsArray: any;
  toggleArchived: () => void;
  showArchived: boolean;
  handleOnChangeSites: (e: DropDownProps[]) => void;
};
const LocationAreaRoleUserFilters = ({
  data,
  type,
  handleOnChangeSites,
  sitesAsSelectOptionsArray,
  rolesAsSelectOptionsArray,
  usersAsSelectOptionsArray,
  handleOnChangeSelect2,
  handleOnChangeProjects,
  areasAsSelectOptionsArray,
  toggleArchived,
  showArchived,
}: DataProps) => {
  const dispatch = useDispatch();
  const projects = useSelector(getProjects);
  const includeCompletedProjects = data.include_completed_projects
  const reportType = type;
  const [selectedProjects, setSelectedProjects] = useState<{ value: string; label: string }[]>([]);

  // If includeCompletedProjects is false then filter out any projects that are not complete
  const filteredProjects = includeCompletedProjects
    ? projects
    : projects.filter(project => !project.completed);

  useEffect(() => {
    // Filter out selected projects that are no longer in the filteredProjects list
    setSelectedProjects(prevSelected =>
      prevSelected.filter(selectedProject =>
        filteredProjects.some(filteredProject => filteredProject.id === selectedProject.value)
      )
    );

    // Pass the selected projects to the parent component
    handleOnChangeProjects(selectedProjects || []);
  }, [includeCompletedProjects]);

  const formattedProjects = filteredProjects.map(project => ({
    value: project.id,
    label: project.name,
  }));
  const handleProjectsChange = (selectedOptions: any) => {  
    // Update state with selected values
    setSelectedProjects(selectedOptions || []);

    // Pass the selected options to the parent component
    handleOnChangeProjects(selectedOptions || []);
  };

  const langPreferences = useSelector(getLangPreferences);
  const getSelectedOptions = (selected: string[], key: string) => {
    const getArray = () => {
      switch (key) {
        case 'areas':
          return areasAsSelectOptionsArray;
        case 'roles':
          return rolesAsSelectOptionsArray;
        case 'users':
          return usersAsSelectOptionsArray;
        case 'sites':
          return sitesAsSelectOptionsArray;
        default:
          break;
      }
      return [];
    };
    const params = getArray();
    if (key === 'areas') {
      return getSelectedGroupedValues(params, selected);
    }
    return getSelectedValues(params, selected);
  };

  useEffect(() => {
    dispatch(GET_PROJECTS_REQUEST());
  }, [dispatch]);
  
  return (
    <>
      <Divider />
      <br />
      <h3>Data</h3>
      <FormContainer maxColumns={3}>
        <FormItem
          label={getPreferenceLabel(
            langPreferences,
            'site',
            'plural',
            '',
            true
          )}
        >
          <Select
            options={sitesAsSelectOptionsArray}
            isMulti={true}
            isCreatable={false}
            hideSelectedOptions={true}
            onChange={(e) => handleOnChangeSites(e)}
            defaultValue={getSelectedOptions(data.sites, 'sites')}
            ariaLabel={getPreferenceLabel(
              langPreferences,
              'site',
              'plural',
              '',
              true
            )}
          />
        </FormItem>
        <FormItem
          label={getPreferenceLabel(
            langPreferences,
            'area',
            'plural',
            '',
            true
          )}
        >
          <Select
            options={areasAsSelectOptionsArray}
            isMulti={true}
            isCreatable={false}
            hideSelectedOptions={true}
            onChange={(e) => handleOnChangeSelect2(e, 'areas')}
            defaultValue={getSelectedOptions(data.areas, 'areas')}
            value={getSelectedOptions(data.areas, 'areas')}
            ariaLabel={getPreferenceLabel(
              langPreferences,
              'area',
              'plural',
              '',
              true
            )}
          />
        </FormItem>
        <FormItem
          label={'Users'}
          labelAddon={
            <span
              style={{ cursor: 'pointer' }}
              onClick={toggleArchived}
              className={
                showArchived ? 'text-color-primary' : 'text-color-gray'
              }
            >
              Show ex-employees
            </span>
          }
        >
          <Select
            options={usersAsSelectOptionsArray}
            isMulti={true}
            hideSelectedOptions={true}
            isCreatable={false}
            onChange={(e) => handleOnChangeSelect2(e, 'users')}
            defaultValue={getSelectedOptions(data.users, 'users')}
            value={getSelectedOptions(data.users, 'users')}
            ariaLabel="Users"
          />
        </FormItem>
        <FormItem
          label={getPreferenceLabel(
            langPreferences,
            'role',
            'plural',
            '',
            true
          )}
        >
          <Select
            hideSelectedOptions={true}
            options={rolesAsSelectOptionsArray}
            isMulti={true}
            isCreatable={false}
            onChange={(e) => handleOnChangeSelect2(e, 'roles')}
            defaultValue={getSelectedOptions(data.roles, 'roles')}
            value={getSelectedOptions(data.roles, 'roles')}
            ariaLabel={getPreferenceLabel(
              langPreferences,
              'role',
              'plural',
              '',
              true
            )}
          />
        </FormItem>
        {reportType === 'timesheet' && (
          <FeatureFlag name="projects">
            <FeatureFlag.On>
              <FormItem
                label={'Projects'}
              >
                <Select
                  options={formattedProjects}
                  isMulti={true}
                  hideSelectedOptions={true}
                  isCreatable={false}
                  onChange={(selectedOptions) => handleProjectsChange(selectedOptions)}
                  defaultValue={formattedProjects}
                  value={selectedProjects}
                  ariaLabel="projects"
                />
              </FormItem>
            </FeatureFlag.On>
          </FeatureFlag>
        )}
      </FormContainer>
    </>
  );
};

export default LocationAreaRoleUserFilters;

